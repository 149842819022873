// import { clearExtendedSession } from '../../invitation_screening/ducks/actions'

const namespace = 'router'

export const HOME_PAGE = `${namespace}/HOME_PAGE`
export const RESET_SESSION = `${namespace}/RESET_SESSION`
export const GATEKEEPER_PAGE = `${namespace}/GATEKEEPER_PAGE`

export const NAVIGATE_PAGE_TO = `${namespace}/NAVIGATE_PAGE_TO`

export const navigateTo = (pageId) => ({
    type:  NAVIGATE_PAGE_TO,
    payload: {
        pageId
    }
})

export const redirectToHomePage = () => navigateTo(HOME_PAGE)

export const resetCurrentSession = () => (dispatch, getState) => {
    // clearExtendedSession()
    dispatch({ type: RESET_SESSION })
}

// auto refresh for changes
// const pageLoadedMoment = m(Date.now())
// const periodicPageReload = setInterval(() => {
//     if (m().diff(pageLoadedMoment, 'hours') > 1)
//        window.location.reload()
// }, 5000)
