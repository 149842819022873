const ROOT_PATH = '/events/20230520佛堂安座'
const THUMBNAIL_PATH = '/thumbnails/20230520佛堂安座'
const VIDEO_PATH = '/videos/20230520佛堂安座'

const eventPhotos = Array.from([
    'Photo-2023-05-19-16-07-40_0636.jpg',
    'Photo-2023-05-19-23-12-49_2579.jpg',
    'Photo-2023-05-20-10-45-07_2580.jpg',
    'Photo-2023-05-20-10-45-52_2581.jpg',
    'Photo-2023-05-20-22-13-50_0639.jpg',
    'Photo-2023-05-20-23-05-17_0641.jpg',
    'Photo-2023-05-20-23-05-18_0644.jpg',
    'Photo-2023-05-20-23-05-19_0646.jpg'
]).map((fileName, i) => ({
    id: i,
    url: `${ROOT_PATH}/${fileName}`,
    thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}) )

const eventVideos = Array.from([
    'Video-2023-06-20-15-30-46_2709.mp4'
]).map((fileName, i) => ({
    id: `v-${i}`,
    url: `${VIDEO_PATH}/${fileName}`,
    // thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}))

const familyEvent = {
    name: '佛堂安座',
    dateString: '2023/05/20',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
