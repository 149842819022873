import PropTypes from 'prop-types'

const EventVideo = ({ url, thumbnailUrl, description }) => {
    return (
        <div className="flex">
            <video muted autoPlay controls playsInline className="w-2/3 mr-2 rounded-lg">
                <source src={ url } type="video/mp4" />
            </video>
            <p className="h-full border-l-2 pl-2 w-1/3 text-secondary text-sm">{ description }</p>
        </div>
    )
}

EventVideo.propTypes = {
    url: PropTypes.string.isRequired,
    // thumbnailUrl: PropTypes.string.isRequired,
    description: PropTypes.string // optional
}

export default EventVideo
