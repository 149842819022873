import { VALIDATE_HASH } from './actions'
import config from '../../config'

const initialState = {
    accessAllowed: config.ACCESS_ALLOWED || false
}

const allowedHashes = [
    '08b789474eb68228d3a73a7ef3376771c0a67b9598997447a3634c36607d4e50',
    '283f87b6d239d07a0d8a2e5845355dd6a55bb26863846434f35227842b94ae65',
    'b2e16829b5bbc8f28584113ec3b9f234f30a1d90af2835746b801daef792b1e5',
    '5fbf57e881e85862c385876983920103044349cb758c9dbe7c8936e851736fb4',
    'd36f450406763d3d5115c0127ab62877c40b134ce179b54ff8ad4619aa216814',
    'ec03ac907b49892bc7f2cc92668d8b98476c8eb4d64a127177756837b873c427'
]

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case VALIDATE_HASH: {
            const digestHex = action.payload

            return {
                ...state,
                accessAllowed: allowedHashes.includes(digestHex)
            }
        }
        default:
            return state
    }
}

export default reducer
