const ROOT_PATH = '/events/20221220阿美姨聚會'
const THUMBNAIL_PATH = '/thumbnails/20221220阿美姨聚會'

const eventPhotos = Array.from([
    'Photo-2023-01-24-14-02-04_8226.jpg',
    'Photo-2023-01-24-14-02-05_8227.jpg'
]).map((fileName, i) => ({
    id: i,
    url: `${ROOT_PATH}/${fileName}`,
    thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}) )

const eventVideos = []

const familyEvent = {
    name: '阿美姨聚會',
    dateString: '2022/12/20',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
