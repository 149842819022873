const ROOT_PATH = '/events/20230123回娘家'
const THUMBNAIL_PATH = '/thumbnails/20230123回娘家'

const eventPhotos = Array.from([
    'Photo-2023-01-23-10-32-49_0462.jpg',
    'Photo-2023-01-23-14-33-03_8210.jpg',
    'Photo-2023-01-23-14-33-05_8211.jpg',
    'Photo-2023-01-23-14-33-20_8212.jpg',
    'Photo-2023-01-23-16-58-06_8215.jpg',
    'Photo-2023-01-23-20-43-40_0463.jpg',
    'Photo-2023-01-23-20-43-47_0464.jpg'
]).map((fileName, i) => ({
    id: i,
    url: `${ROOT_PATH}/${fileName}`,
    thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}) )

const eventVideos = []

const familyEvent = {
    name: '回娘家',
    dateString: '2023/01/23',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
