import config from '../../config'

import {
    HOME_PAGE,
    GATEKEEPER_PAGE,
    NAVIGATE_PAGE_TO
} from './actions'

const initialPage = (() => {
    const initialPathname = window.location.pathname

    switch(initialPathname) {
        case '/':
            return GATEKEEPER_PAGE
        case '/events/':
            return HOME_PAGE
        default: {
            return GATEKEEPER_PAGE
        }
    }
})()

const initialState = {
    currentPage: (config.DEBUG_MODE && config.INITIAL_PAGE) || initialPage
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case NAVIGATE_PAGE_TO: {
            return {
                ...state,
                currentPage: action.payload.pageId
            }
        }
        default:
            return state
    }
}

export default reducer
