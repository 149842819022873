const namespace = 'gatekeeper'

export const VALIDATE_HASH = `${namespace}/VALIDATE_HASH`

const digestMessage = async (message) => {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join(""); // convert bytes to hex string
    return hashHex;
}

export const validateHash = (text) => (dispatch) => {
    digestMessage(text).then(digestHex => {

        dispatch({
            type: VALIDATE_HASH,
            payload: digestHex
        })
    })
}
