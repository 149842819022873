const ROOT_PATH = '/events/20221217與尚毅的家庭日'
const THUMBNAIL_PATH = '/thumbnails/20221217與尚毅的家庭日'
const VIDEO_PATH = '/videos/20221217與尚毅的家庭日'

const eventPhotos = Array.from([
    'Photo-2022-12-17-16-31-44_0107.jpg',
    'Photo-2022-12-17-21-41-36_7928.jpg',
    'Photo-2022-12-20-18-20-31_0146.jpg',
    'Photo-2022-12-20-18-20-44_0147.jpg',
    'Photo-2022-12-20-18-20-47_0148.jpg'
]).map((fileName, i) => ({
    id: `p-${i}`,
    url: `${ROOT_PATH}/${fileName}`,
    thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}) )

const eventVideos = Array.from([
    'Video-2022-12-17-21-44-18_7934.mp4'
]).map((fileName, i) => ({
    id: `v-${i}`,
    url: `${VIDEO_PATH}/${fileName}`,
    // thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}))

const familyEvent = {
    name: '與尚毅的家庭日',
    dateString: '2022/12/17',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
