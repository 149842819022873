import { combineReducers } from 'redux'
import { RESET_SESSION } from '../router/ducks/actions'
import router from '../router'
import home from '../home'
import gatekeeper from '../gatekeeper'

const appReducer = combineReducers({
    router,
    home,
    gatekeeper
})

const rootReducer = (state, action) => appReducer(
    (action.type === RESET_SESSION ? undefined : state),
    action
)

export default rootReducer
