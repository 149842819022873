const ROOT_PATH = '/events/20221125婚禮'
const THUMBNAIL_PATH = '/thumbnails/20221125婚禮'

const eventPhotos = Array.from([
    'a_MTB - 1013.jpg',
    'a_MTB - 1684.jpg',
    'a_MTB - 233.jpg',
    'a_MTB - 408.jpg',
    'a_MTB - 1042.jpg',
    'a_MTB - 1688.jpg',
    'a_MTB - 2399.jpg',
    'a_MTB - 414.jpg',
    'a_MTB - 1052.jpg',
    'a_MTB - 1705.jpg',
    'a_MTB - 246.jpg',
    'a_MTB - 419.jpg',
    'a_MTB - 1064.jpg',
    'a_MTB - 1710.jpg',
    'a_MTB - 2502.jpg',
    'a_MTB - 447.jpg',
    'a_MTB - 1082.jpg',
    'a_MTB - 171.jpg',
    'a_MTB - 2510.jpg',
    'a_MTB - 461.jpg',
    'a_MTB - 1088.jpg',
    'a_MTB - 1785.jpg',
    'a_MTB - 2517.jpg',
    'a_MTB - 489.jpg',
    'a_MTB - 1089.jpg',
    'a_MTB - 1790.jpg',
    'a_MTB - 2524.jpg',
    'a_MTB - 532.jpg',
    'a_MTB - 1097.jpg',
    'a_MTB - 1819.jpg',
    'a_MTB - 2527.jpg',
    'a_MTB - 578.jpg',
    'a_MTB - 1104.jpg',
    'a_MTB - 1857.jpg',
    'a_MTB - 2528.jpg',
    'a_MTB - 587.jpg',
    'a_MTB - 1121.jpg',
    'a_MTB - 1907.jpg',
    'a_MTB - 257.jpg',
    'a_MTB - 591.jpg',
    'a_MTB - 1134.jpg',
    'a_MTB - 1910.jpg',
    'a_MTB - 2601.jpg',
    'a_MTB - 601.jpg',
    'a_MTB - 1137.jpg',
    'a_MTB - 192.jpg',
    'a_MTB - 2614.jpg',
    'a_MTB - 609.jpg',
    'a_MTB - 1146.jpg',
    'a_MTB - 1955.jpg',
    'a_MTB - 2639.jpg',
    'a_MTB - 616.jpg',
    'a_MTB - 1150.jpg',
    'a_MTB - 1985.jpg',
    'a_MTB - 2641.jpg',
    'a_MTB - 623.jpg',
    'a_MTB - 1157.jpg',
    'a_MTB - 2007.jpg',
    'a_MTB - 2646.jpg',
    'a_MTB - 658.jpg',
    'a_MTB - 1176.jpg',
    'a_MTB - 2014.jpg',
    'a_MTB - 2651.jpg',
    'a_MTB - 660.jpg',
    'a_MTB - 1179.jpg',
    'a_MTB - 2024.jpg',
    'a_MTB - 2653.jpg',
    'a_MTB - 664.jpg',
    'a_MTB - 1188.jpg',
    'a_MTB - 2030.jpg',
    'a_MTB - 269.jpg',
    'a_MTB - 669.jpg',
    'a_MTB - 1196.jpg',
    'a_MTB - 2055.jpg',
    'a_MTB - 287.jpg',
    'a_MTB - 672.jpg',
    'a_MTB - 1199.jpg',
    'a_MTB - 2068.jpg',
    'a_MTB - 28.jpg',
    'a_MTB - 682.jpg',
    'a_MTB - 1212.jpg',
    'a_MTB - 2072.jpg',
    'a_MTB - 292.jpg',
    'a_MTB - 684.jpg',
    'a_MTB - 1220.jpg',
    'a_MTB - 2077.jpg',
    'a_MTB - 296.jpg',
    'a_MTB - 686.jpg',
    'a_MTB - 1230.jpg',
    'a_MTB - 2090.jpg',
    'a_MTB - 299.jpg',
    'a_MTB - 695.jpg',
    'a_MTB - 1240.jpg',
    'a_MTB - 2108.jpg',
    'a_MTB - 320.jpg',
    'a_MTB - 701.jpg',
    'a_MTB - 1248.jpg',
    'a_MTB - 2115.jpg',
    'a_MTB - 326.jpg',
    'a_MTB - 706.jpg',
    'a_MTB - 1260.jpg',
    'a_MTB - 2120.jpg',
    'a_MTB - 333.jpg',
    'a_MTB - 722.jpg',
    'a_MTB - 140.jpg',
    'a_MTB - 2128.jpg',
    'a_MTB - 3385.jpg',
    'a_MTB - 725.jpg',
    'a_MTB - 1526.jpg',
    'a_MTB - 2134.jpg',
    'a_MTB - 3404.jpg',
    'a_MTB - 746.jpg',
    'a_MTB - 1549.jpg',
    'a_MTB - 2139.jpg',
    'a_MTB - 3490.jpg',
    'a_MTB - 757.jpg',
    'a_MTB - 1551.jpg',
    'a_MTB - 2150.jpg',
    'a_MTB - 3502.jpg',
    'a_MTB - 773.jpg',
    'a_MTB - 1587.jpg',
    'a_MTB - 217.jpg',
    'a_MTB - 3535.jpg',
    'a_MTB - 788.jpg',
    'a_MTB - 1595.jpg',
    'a_MTB - 2253.jpg',
    'a_MTB - 353.jpg',
    'a_MTB - 86.jpg',
    'a_MTB - 1622.jpg',
    'a_MTB - 2263.jpg',
    'a_MTB - 3618.jpg',
    'a_MTB - 88.jpg',
    'a_MTB - 1654.jpg',
    'a_MTB - 2276.jpg',
    'a_MTB - 3630.jpg',
    'a_MTB - 990.jpg',
    'a_MTB - 1665.jpg',
    'a_MTB - 2291.jpg',
    'a_MTB - 3647.jpg',
    'a_MTB - 995.jpg',
    'a_MTB - 1678.jpg',
    'a_MTB - 2299.jpg',
    'a_MTB - 3729.jpg',
]).map((fileName, i) => ({ id: i, url: `${ROOT_PATH}/${fileName}`, thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`, description: fileName }) )

const eventVideos = []
// Array.from([{
//     url: '',
//     thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
//     description: ''
// }]).map((e, i) => { id: i, ...e })

const familyEvent = {
    name: '婚禮',
    dateString: '2022/11/25',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
