const ROOT_PATH = '/events/20221224台中媽慶生與平安夜'
const THUMBNAIL_PATH = '/thumbnails/20221224台中媽慶生與平安夜'
const VIDEO_PATH = '/videos/20221224台中媽慶生與平安夜'

const eventPhotos = Array.from([
    'Photo-2022-12-24-22-04-31_0171.jpg',
    'Photo-2022-12-24-22-10-22_8015.jpg',
    'Photo-2022-12-24-22-11-47_8016.jpg',
    'Photo-2022-12-24-22-12-01_8019.jpg',
    'Photo-2022-12-24-22-12-59_0175.jpg',
    'Photo-2022-12-24-22-40-25_8023.jpg'
]).map((fileName, i) => ({
    id: `p-${i}`,
    url: `${ROOT_PATH}/${fileName}`,
    thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}) )

const eventVideos = Array.from([
    'Video-2022-12-24-22-40-16_8025.mp4'
]).map((fileName, i) => ({
    id: `v-${i}`,
    url: `${VIDEO_PATH}/${fileName}`,
    // thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}))

const familyEvent = {
    name: '台中媽慶生與平安夜',
    dateString: '2022/12/24',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
