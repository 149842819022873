const ROOT_PATH = '/events/2023墨爾本烹飪集合'
const THUMBNAIL_PATH = '/thumbnails/2023墨爾本烹飪集合'

const eventPhotos = Array.from([
    'Photo-2023-02-11-18-50-32_8573.jpg',
    'Photo-2023-02-11-18-50-35_8574.jpg',
    'Photo-2023-02-11-18-50-44_8576.jpg',
    'Photo-2023-02-11-19-01-44_8577.jpg',
    'Photo-2023-02-12-10-34-49_8590.jpg',
    'Photo-2023-02-16-17-05-49_8713.jpg',
    'Photo-2023-02-22-16-54-23_0965.jpg',
    'Photo-2023-02-22-16-54-27_0966.jpg',
    'Photo-2023-02-22-16-54-31_0967.jpg',
    'Photo-2023-03-09-19-05-19_1026.jpg',
    'Photo-2023-03-12-13-05-18_1031.jpg',
    'Photo-2023-03-12-13-05-27_1032.jpg',
    'Photo-2023-03-12-13-05-35_1033.jpg',
    'Photo-2023-03-12-13-05-40_1034.jpg',
    'Photo-2023-03-14-19-49-40_1048.jpg',
    'Photo-2023-03-14-19-49-44_1049.jpg',
    'Photo-2023-03-14-19-49-46_1050.jpg',
    'Photo-2023-03-14-19-49-49_1051.jpg',
    'Photo-2023-03-19-18-22-29_1061.jpg',
    'Photo-2023-03-19-18-29-45_1064.jpg',
    'Photo-2023-03-22-19-23-57_1077.jpg',
    'Photo-2023-04-04-18-52-54_1118.jpg',
    'Photo-2023-04-05-16-47-48_1123.jpg',
    'Photo-2023-04-05-16-48-08_1124.jpg',
    'Photo-2023-04-08-18-20-05_1128.jpg',
    'Photo-2023-04-08-18-20-12_1129.jpg',
    'Photo-2023-04-08-18-20-18_1130.jpg',
    'Photo-2023-04-08-18-20-26_1131.jpg',
    'Photo-2023-04-08-18-20-32_1132.jpg',
    'Photo-2023-04-12-18-23-23_1148.jpg',
    'Photo-2023-04-12-18-23-34_1150.jpg',
    'Photo-2023-04-12-18-23-39_1151.jpg',
    'Photo-2023-05-27-17-39-27_2627.jpg',
    'Photo-2023-05-27-17-39-39_2628.jpg',
    'Photo-2023-05-27-17-39-45_2629.jpg',
    'Photo-2023-05-28-18-45-16_2630.jpg',
    'Photo-2023-06-02-19-16-34_2648.jpg',
    'Photo-2023-06-02-19-16-37_2649.jpg',
    'Photo-2023-06-22-18-10-13_0774.jpg',
    'Photo-2023-06-22-18-12-44_0777.jpg',
    'Photo-2023-06-24-17-49-49_2743.jpg',
    'Photo-2023-06-24-17-49-52_2744.jpg',
    'Photo-2023-06-24-17-50-16_2746.jpg',
]).map((fileName, i) => ({
    id: i,
    url: `${ROOT_PATH}/${fileName}`,
    thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}) )

// Video-2023-05-27-12-46-19_2618.mov
const eventVideos = []

const familyEvent = {
    name: '2023墨爾本烹飪集合',
    dateString: '2023',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
