const ROOT_PATH = '/events/20230121除夕年夜團圓'
const THUMBNAIL_PATH = '/thumbnails/20230121除夕年夜團圓'
const VIDEO_PATH = '/videos/20230121除夕年夜團圓'

const eventPhotos = Array.from([
    'Photo-2023-01-21-20-34-56_0446.jpg',
    'Photo-2023-01-21-20-35-52_8189.jpg',
    'Photo-2023-01-21-20-38-44_0447.jpg',
    'Photo-2023-01-21-20-38-59_0449.jpg',
    'Photo-2023-01-21-20-39-27_0450.jpg',
    'Photo-2023-01-21-20-39-35_0451.jpg',
    'Photo-2023-01-21-21-29-18_8196.jpg',
    'Photo-2023-01-21-21-29-37_8197.jpg',
    'Photo-2023-01-21-21-47-30_8199.jpg',
    'Photo-2023-01-21-21-48-13_8202.jpg',
    'Photo-2023-01-21-21-49-02_8204.jpg',
    'Photo-2023-01-21-22-54-13_8205.jpg'
]).map((fileName, i) => ({
    id: i,
    url: `${ROOT_PATH}/${fileName}`,
    thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}) )

const eventVideos = Array.from([
    'Video-2023-01-20-16-20-44_0457.mp4',
    // 'Video-2023-01-21-11-45-10_0458.mp4', // broken?
    'Video-2023-01-21-21-04-39_0460.mp4',
    'Video-2023-01-21-21-09-53_8194.mp4'
]).map((fileName, i) => ({
    id: `v-${i}`,
    url: `${VIDEO_PATH}/${fileName}`,
    // thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}))

const familyEvent = {
    name: '除夕年夜團圓',
    dateString: '2023/01/21',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
