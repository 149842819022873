const ROOT_PATH = '/events/20230128大伯二姑聚會'
const THUMBNAIL_PATH = '/thumbnails/20230128大伯二姑聚會'

const eventPhotos = Array.from([
    'Photo-2023-01-28-14-21-17_8359.jpg',
    'Photo-2023-01-28-16-06-44_8366.jpg',
    'Photo-2023-01-28-16-07-42_8378.jpg',
    'Photo-2023-01-28-16-45-58_8385.jpg',
    'Photo-2023-01-28-16-46-29_8394.jpg',
    'Photo-2023-01-28-16-47-02_8399.jpg',
    'Photo-2023-01-28-17-50-40_8400.jpg'
]).map((fileName, i) => ({
    id: i,
    url: `${ROOT_PATH}/${fileName}`,
    thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}) )

const eventVideos = []

const familyEvent = {
    name: '大伯二姑聚會',
    dateString: '2023/01/28',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
