import { useEffect } from 'react'
import { connect } from 'react-redux'
import FamilyEvent from '../family_event/FamilyEvent'
import familyEvents from './ducks/family_events'

const HomePage = () => {
    useEffect(() => {
        document.title = "歡迎爸爸媽媽們"
    })

    return (
        <div className="leading-loose p-8">
            <div className="text-right">
                <h1 className="text-4xl font-bold mb-12 text-secondary">嗨!</h1>
                <p className="text-lg text-secondary mb-4">歡迎Eddy及Rita的爸爸媽媽們來發落我們的日常</p>
                <p>未來藉由這個空間，記錄我們結婚後的生活大小事</p>
                <p>我們想念彼此的時候，除了視訊通話之外，也可以到這裏，想看就看</p>
            </div>
            <hr className="my-8 border-secondary" />
            {
                familyEvents.map((fe, i) =>
                    <div key={ i }>
                        <FamilyEvent viewIsOpened={ i === 1 }
                            { ...fe } />
                        <hr className="my-8 border-secondary" />
                    </div>
                )
            }


        </div>
    )
}

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps)(HomePage)
