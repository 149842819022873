const ROOT_PATH = '/events/20230424動物園'
const THUMBNAIL_PATH = '/thumbnails/20230424動物園'
const VIDEO_PATH = '/videos/20230424動物園'

const eventPhotos = Array.from([
    'Photo-2023-04-24-12-32-07_1214.jpg',
    'Photo-2023-04-24-12-32-21_1215.jpg',
    'Photo-2023-04-24-12-32-40_1216.jpg',
    'Photo-2023-04-24-12-32-48_1217.jpg',
    'Photo-2023-04-24-12-39-24_1222.jpg',
    'Photo-2023-04-24-12-39-56_1223.jpg',
    'Photo-2023-04-24-12-41-25_1227.jpg',
    'Photo-2023-04-24-13-12-43_1232.jpg',
    'Photo-2023-04-24-14-32-40_1236.jpg'
]).map((fileName, i) => ({
    id: i,
    url: `${ROOT_PATH}/${fileName}`,
    thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}) )

const eventVideos = Array.from([
    'Video-2023-04-24-12-42-49_1228.mp4'
]).map((fileName, i) => ({
    id: `v-${i}`,
    url: `${VIDEO_PATH}/${fileName}`,
    // thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}))

const familyEvent = {
    name: '動物園',
    dateString: '2023/04/24',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
