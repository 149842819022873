const ROOT_PATH = '/events/20221201團圓年夜飯'
const THUMBNAIL_PATH = '/thumbnails/20221201團圓年夜飯'

const eventPhotos = Array.from([
    'Photo-2022-12-05-00-18-39_9989.jpg',
    'Photo-2022-12-05-00-18-39_9990.jpg',
    'Photo-2022-12-05-00-18-40_9991.jpg'
]).map((fileName, i) => ({ id: i, url: `${ROOT_PATH}/${fileName}`, thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`, description: fileName }) )

const eventVideos = []

const familyEvent = {
    name: '團圓年夜飯',
    dateString: '2022/12/01',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
