const ROOT_PATH = '/events/20230129大姨聚會'
const THUMBNAIL_PATH = '/thumbnails/20230129大姨聚會'

const eventPhotos = Array.from([
    'Photo-2023-01-29-14-54-35_8401.jpg',
    'Photo-2023-01-29-14-54-42_8408.jpg',
    'Photo-2023-01-29-14-54-50_8409.jpg',
    'Photo-2023-01-29-22-05-54_8421.jpg',
]).map((fileName, i) => ({
    id: i,
    url: `${ROOT_PATH}/${fileName}`,
    thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}) )

const eventVideos = []

const familyEvent = {
    name: '大姨聚會',
    dateString: '2023/01/29',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
