const ROOT_PATH = '/events/20221204與尚毅的家庭日'
const THUMBNAIL_PATH = '/thumbnails/20221204與尚毅的家庭日'

const eventPhotos = Array.from([
    'Photo-2022-12-04-14-49-54_7833.jpg',
    'Photo-2022-12-04-16-41-37_7838.jpg',
    'Photo-2022-12-04-16-46-47_7840.jpg',
    'Photo-2022-12-04-18-18-52_7841.jpg',
    'Photo-2022-12-05-01-19-24_7845.jpg'
]).map((fileName, i) => ({
    id: i,
    url: `${ROOT_PATH}/${fileName}`,
    thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}) )

const eventVideos = []

const familyEvent = {
    name: '與尚毅的家庭日',
    dateString: '2022/12/04',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
