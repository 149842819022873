const ROOT_PATH = '/events/20230413 Yara巧克力工廠'
const THUMBNAIL_PATH = '/thumbnails/20230413 Yara巧克力工廠'
const VIDEO_PATH = '/videos/20230413 Yara巧克力工廠'

const eventPhotos = Array.from([
    'Photo-2023-04-13-15-05-30_1162.jpg',
    'Photo-2023-04-13-15-11-58_1165.jpg',
    'Photo-2023-04-13-15-13-19_1169.jpg',
    'Photo-2023-04-13-15-13-33_1170.jpg'
]).map((fileName, i) => ({
    id: i,
    url: `${ROOT_PATH}/${fileName}`,
    thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}) )

const eventVideos = Array.from([
    'Video-2023-04-13-15-05-41_1164.mp4',
    'Video-2023-04-13-14-00-04_1160.mp4'
]).map((fileName, i) => ({
    id: `v-${i}`,
    url: `${VIDEO_PATH}/${fileName}`,
    // thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}))

const familyEvent = {
    name: 'Yara巧克力工廠',
    dateString: '2023/04/13',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
