const ROOT_PATH = '/events/20220825與姑姑聚餐'
const THUMBNAIL_PATH = '/thumbnails/20220825與姑姑聚餐'

const fileNames = [
    'Photo-2022-08-25-20-14-12_7035.jpg',
    'Photo-2022-08-25-20-15-38_7036.jpg',
    'Photo-2022-08-25-20-44-40_7038.jpg',
    'Photo-2022-08-25-21-07-51_7039.jpg',
    'Photo-2022-08-25-21-09-31_7040.jpg',
    'Photo-2022-08-25-21-09-36_7041.jpg',
    'Photo-2022-08-25-21-15-35_7044.jpg'
]

const eventPhotos = Array.from(fileNames)
    .map((fileName, i) => ({
        id: i,
        url: `${ROOT_PATH}/${fileName}`,
        thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
        description: fileName
    }))

// const eventPhotos = Array.from([{
//     url: `${ ROOT_PATH }/Photo-2022-08-25-20-14-12_7035.jpg`,
//     thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
//     description: '與姑姑聚餐'
// }, {
//     url: `${ ROOT_PATH }/Photo-2022-08-25-20-15-38_7036.jpg`,
//     thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
//     description: '與姑姑聚餐'
// }, {
//     url: `${ ROOT_PATH }/Photo-2022-08-25-20-44-40_7038.jpg`,
//     thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
//     description: '與姑姑聚餐'
// }, {
//     url: `${ ROOT_PATH }/Photo-2022-08-25-21-07-51_7039.jpg`,
//     thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
//     description: '與姑姑聚餐'
// }, {
//     url: `${ ROOT_PATH }/Photo-2022-08-25-21-09-31_7040.jpg`,
//     thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
//     description: '與姑姑聚餐'
// },  {
//     url: `${ ROOT_PATH }/Photo-2022-08-25-21-09-36_7041.jpg`,
//     thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
//     description: '與姑姑聚餐'
// }, {
//     url: `${ ROOT_PATH }/Photo-2022-08-25-21-15-35_7044.jpg`,
//     thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
//     description: '與姑姑聚餐'
// }]).map((e, i) => ({ id: i, ...e }) )

const eventVideos = []
// Array.from([{
//     url: '',
//     thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
//     description: ''
// }]).map((e, i) => { id: i, ...e })

const familyEvent = {
    name: '與姑姑聚餐',
    dateString: '2022/08/25',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
