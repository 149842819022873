const ROOT_PATH = '/events/20230204桃園-墨爾本'
const THUMBNAIL_PATH = '/thumbnails/20230204桃園-墨爾本'

const eventPhotos = Array.from([
    'Photo-2023-02-04-21-59-44_0698.jpg',
    'Photo-2023-02-04-23-50-21_0700.jpg',
    'Photo-2023-02-04-23-56-51_0702.jpg',
    'Photo-2023-02-04-23-57-00_0704.jpg',
    'Photo-2023-02-04-23-57-03_0705.jpg',
    'Photo-2023-02-05-00-00-49_0707.jpg',
    'Photo-2023-02-05-00-14-41_0708.jpg',
    'Photo-2023-02-05-05-06-38_0715.jpg',
    'Photo-2023-02-05-05-06-59_0716.jpg',
    'Photo-2023-02-06-20-22-16_8492.jpg'
]).map((fileName, i) => ({
    id: i,
    url: `${ROOT_PATH}/${fileName}`,
    thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}) )

const eventVideos = []

const familyEvent = {
    name: '桃園-墨爾本',
    dateString: '2023/02/04',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
