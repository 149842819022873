import configOverrides from './config.env'

const host = window.location.origin

const config = {
    DEBUG_MODE: false,
    API_BASE_URL: `${host}/api`,
    ...configOverrides
}

export default config
