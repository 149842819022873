const ROOT_PATH = '/events/20230117虎頭山環保運動公園'
const THUMBNAIL_PATH = '/thumbnails/20230117虎頭山環保運動公園'

const eventPhotos = Array.from([
    'Photo-2023-01-18-02-51-38_0365.jpg',
    'Photo-2023-01-18-02-51-38_0367.jpg',
    'Photo-2023-01-18-02-51-39_0368.jpg',
    'Photo-2023-01-18-02-51-39_0369.jpg',
    'Photo-2023-01-18-02-51-39_0370.jpg',
    'Photo-2023-01-18-02-51-40_0371.jpg',
    'Photo-2023-01-18-02-51-40_0372.jpg',
    'Photo-2023-01-18-02-51-40_0373.jpg',
    'Photo-2023-01-18-02-51-44_0384.jpg',
    'Photo-2023-01-18-02-51-44_0385.jpg',
    'Photo-2023-01-18-02-51-47_0394.jpg',
    'Photo-2023-01-18-02-51-48_0395.jpg',
    'Photo-2023-01-18-02-51-51_0404.jpg'
]).map((fileName, i) => ({
    id: i,
    url: `${ROOT_PATH}/${fileName}`,
    thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}) )

const eventVideos = []

const familyEvent = {
    name: '虎頭山環保運動公園',
    dateString: '2023/01/17',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
