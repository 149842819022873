const ROOT_PATH = '/events/20230429-20230508德瑞蜜月'
const THUMBNAIL_PATH = '/thumbnails/20230429-20230508德瑞蜜月'
const VIDEO_PATH = '/videos/20230429-20230508德瑞蜜月'

const eventPhotos = Array.from([
    'Photo-2023-04-29-23-10-11_1257.jpg',
    'Photo-2023-04-30-15-20-56_1263.jpg',
    'Photo-2023-04-30-16-35-43_1264.jpg',
    'Photo-2023-04-30-18-11-49_9065.jpg',
    'Photo-2023-04-30-18-12-26_1275.jpg',
    'Photo-2023-04-30-18-18-39_1280.jpg',
    'Photo-2023-04-30-18-21-10_9071.jpg',
    'Photo-2023-04-30-18-45-56_9072.jpg',
    'Photo-2023-04-30-18-46-20_9076.jpg',
    'Photo-2023-04-30-19-09-17_1282.jpg',
    'Photo-2023-04-30-19-38-02_9080.jpg',
    'Photo-2023-04-30-19-43-46_9086.jpg',
    'Photo-2023-04-30-19-44-18_9088.jpg',
    'Photo-2023-04-30-20-15-29_1294.jpg',
    'Photo-2023-04-30-20-15-37_1295.jpg',
    'Photo-2023-04-30-20-27-51_1296.jpg',
    'Photo-2023-04-30-21-26-17_9096.jpg',
    'Photo-2023-04-30-23-32-34_1297.jpg',
    'Photo-2023-04-30-23-42-00_1302.jpg',
    'Photo-2023-05-01-00-11-31_1304.jpg',
    'Photo-2023-05-01-00-13-42_1307.jpg',
    'Photo-2023-05-01-00-17-41_1312.jpg',
    'Photo-2023-05-01-00-29-33_1313.jpg',
    'Photo-2023-05-01-00-30-21_1314.jpg',
    'Photo-2023-05-01-00-30-30_1316.jpg',
    'Photo-2023-05-01-00-39-13_1317.jpg',
    'Photo-2023-05-01-01-15-03_1320.jpg',
    'Photo-2023-05-01-01-33-16_1321.jpg',
    'Photo-2023-05-01-01-36-54_1322.jpg',
    'Photo-2023-05-01-01-37-43_1325.jpg',
    'Photo-2023-05-01-01-48-27_1330.jpg',
    'Photo-2023-05-01-01-48-54_1332.jpg',
    'Photo-2023-05-01-01-56-39_1334.jpg',
    'Photo-2023-05-01-01-57-53_1335.jpg',
    'Photo-2023-05-01-01-58-35_1337.jpg',
    'Photo-2023-05-01-01-59-19_9115.jpg',
    'Photo-2023-05-01-02-00-26_1338.jpg',
    'Photo-2023-05-01-02-00-45_1340.jpg',
    'Photo-2023-05-01-03-35-32_1341.jpg',
    'Photo-2023-05-01-16-15-29_1345.jpg',
    'Photo-2023-05-01-16-16-08_1346.jpg',
    'Photo-2023-05-01-16-16-24_1347.jpg',
    'Photo-2023-05-01-18-12-52_1356.jpg',
    'Photo-2023-05-01-18-18-13_1367.jpg',
    'Photo-2023-05-01-18-18-37_1368.jpg',
    'Photo-2023-05-01-18-35-50_9127.jpg',
    'Photo-2023-05-01-18-36-18_9129.jpg',
    'Photo-2023-05-01-18-37-25_9132.jpg',
    'Photo-2023-05-01-18-52-36_9134.jpg',
    'Photo-2023-05-01-18-52-50_9135.jpg',
    'Photo-2023-05-01-18-53-00_9136.jpg',
    'Photo-2023-05-01-18-56-49_9137.jpg',
    'Photo-2023-05-01-18-57-46_9139.jpg',
    'Photo-2023-05-01-18-59-21_9142.jpg',
    'Photo-2023-05-01-19-00-10_9160.jpg',
    'Photo-2023-05-01-19-14-12_9175.jpg',
    'Photo-2023-05-01-19-25-56_9183.jpg',
    'Photo-2023-05-01-19-26-56_9184.jpg',
    'Photo-2023-05-01-19-30-50_9221.jpg',
    'Photo-2023-05-01-19-32-47_9238.jpg',
    'Photo-2023-05-01-19-33-01_9239.jpg',
    'Photo-2023-05-01-19-33-54_9243.jpg',
    'Photo-2023-05-01-19-57-34_1370.jpg',
    'Photo-2023-05-01-19-59-15_9251.jpg',
    'Photo-2023-05-01-19-59-56_9253.jpg',
    'Photo-2023-05-01-20-13-30_9261.jpg',
    'Photo-2023-05-01-20-37-42_9262.jpg',
    'Photo-2023-05-01-21-32-22_9264.jpg',
    'Photo-2023-05-01-21-32-35_9265.jpg',
    'Photo-2023-05-02-00-52-17_9294.jpg',
    'Photo-2023-05-02-01-02-33_9320.jpg',
    'Photo-2023-05-02-01-09-58_9346.jpg',
    'Photo-2023-05-02-02-35-43_9359.jpg',
    'Photo-2023-05-02-03-17-16_1371.jpg',
    'Photo-2023-05-02-03-35-23_9360.jpg',
    'Photo-2023-05-02-04-02-31_9361.jpg',
    'Photo-2023-05-02-04-03-00_9363.jpg',
    'Photo-2023-05-02-04-03-22_9364.jpg',
    'Photo-2023-05-02-18-08-08_1373.jpg',
    'Photo-2023-05-02-18-25-34_9374.jpg',
    'Photo-2023-05-02-18-27-39_9376.jpg',
    'Photo-2023-05-02-18-27-42_9377.jpg',
    'Photo-2023-05-02-18-28-33_9382.jpg',
    'Photo-2023-05-02-18-35-54_9392.jpg',
    'Photo-2023-05-02-18-36-17_9396.jpg',
    'Photo-2023-05-02-18-59-16_9399.jpg',
    'Photo-2023-05-02-21-47-45_9416.jpg',
    'Photo-2023-05-02-21-48-04_9422.jpg',
    'Photo-2023-05-02-23-17-00_1386.jpg',
    'Photo-2023-05-02-23-21-18_9423.jpg',
    'Photo-2023-05-02-23-26-52_9439.jpg',
    'Photo-2023-05-02-23-28-27_9446.jpg',
    'Photo-2023-05-02-23-45-30_9465.jpg',
    'Photo-2023-05-02-23-46-57_9471.jpg',
    'Photo-2023-05-02-23-58-40_9482.jpg',
    'Photo-2023-05-03-01-00-12_1389.jpg',
    'Photo-2023-05-03-01-20-03_9492.jpg',
    'Photo-2023-05-03-01-20-25_9495.jpg',
    'Photo-2023-05-03-01-42-34_9511.jpg',
    'Photo-2023-05-03-01-47-39_9517.jpg',
    'Photo-2023-05-03-01-53-19_9531.jpg',
    'Photo-2023-05-03-01-57-04_9532.jpg',
    'Photo-2023-05-03-02-11-54_1391.jpg',
    'Photo-2023-05-03-03-29-57_1394.jpg',
    'Photo-2023-05-03-18-18-06_9537.jpg',
    'Photo-2023-05-03-18-20-34_9538.jpg',
    'Photo-2023-05-03-18-20-39_9540.jpg',
    'Photo-2023-05-03-18-21-34_9550.jpg',
    'Photo-2023-05-03-18-39-43_9571.jpg',
    'Photo-2023-05-03-18-40-32_9582.jpg',
    'Photo-2023-05-03-18-54-23_9590.jpg',
    'Photo-2023-05-03-19-13-56_9602.jpg',
    'Photo-2023-05-03-19-15-58_9614.jpg',
    'Photo-2023-05-03-19-20-53_9634.jpg',
    'Photo-2023-05-03-19-30-07_9642.jpg',
    'Photo-2023-05-03-19-37-45_9663.jpg',
    'Photo-2023-05-03-19-39-46_9691.jpg',
    'Photo-2023-05-03-19-51-46_9705.jpg',
    'Photo-2023-05-03-19-51-50_9706.jpg',
    'Photo-2023-05-03-19-51-54_9707.jpg',
    'Photo-2023-05-03-19-58-41_7749.jpg',
    'Photo-2023-05-03-21-09-19_9711.jpg',
    'Photo-2023-05-03-21-10-07_9714.jpg',
    'Photo-2023-05-03-21-46-28_9752.jpg',
    'Photo-2023-05-03-21-49-55_9786.jpg',
    'Photo-2023-05-03-21-51-09_9791.jpg',
    'Photo-2023-05-03-21-52-57_9794.jpg',
    'Photo-2023-05-03-22-01-59_9798.jpg',
    'Photo-2023-05-04-01-54-15_9821.jpg',
    'Photo-2023-05-04-01-54-44_9824.jpg',
    'Photo-2023-05-04-02-24-12_9830.jpg',
    'Photo-2023-05-04-02-26-49_9831.jpg',
    'Photo-2023-05-04-02-33-06_9835.jpg',
    'Photo-2023-05-04-03-38-38_9837.jpg',
    'Photo-2023-05-04-03-39-02_9842.jpg',
    'Photo-2023-05-04-03-39-07_9843.jpg',
    'Photo-2023-05-04-03-39-45_9844.jpg',
    'Photo-2023-05-04-03-40-17_9849.jpg',
    'Photo-2023-05-04-03-43-49_9851.jpg',
    'Photo-2023-05-04-03-45-40_9852.jpg',
    'Photo-2023-05-04-03-45-58_9853.jpg',
    'Photo-2023-05-04-03-49-21_9855.jpg',
    'Photo-2023-05-04-17-56-44_9893.jpg',
    'Photo-2023-05-04-18-17-56_1407.jpg',
    'Photo-2023-05-04-18-23-04_1408.jpg',
    'Photo-2023-05-04-19-54-51_9897.jpg',
    'Photo-2023-05-04-21-14-21_9906.jpg',
    'Photo-2023-05-04-21-14-57_9909.jpg',
    'Photo-2023-05-04-21-17-55_9916.jpg',
    'Photo-2023-05-04-21-24-12_9921.jpg',
    'Photo-2023-05-04-21-27-26_9926.jpg',
    'Photo-2023-05-05-01-04-09_9927.jpg',
    'Photo-2023-05-05-02-32-55_9937.jpg',
    'Photo-2023-05-05-02-32-57_9938.jpg',
    'Photo-2023-05-05-02-42-45_9944.jpg',
    'Photo-2023-05-05-02-54-22_9949.jpg',
    'Photo-2023-05-05-02-54-25_9950.jpg',
    'Photo-2023-05-05-02-54-31_9952.jpg',
    'Photo-2023-05-05-02-55-06_9957.jpg',
    'Photo-2023-05-05-02-58-30_9974.jpg',
    'Photo-2023-05-05-02-58-53_9978.jpg',
    'Photo-2023-05-05-03-03-39_9983.jpg',
    'Photo-2023-05-05-03-03-45_9984.jpg',
    'Photo-2023-05-05-03-04-29_9986.jpg',
    'Photo-2023-05-05-03-04-45_1921.jpg',
    'Photo-2023-05-05-03-04-45_9987.jpg',
    'Photo-2023-05-05-03-04-53_9988.jpg',
    'Photo-2023-05-05-03-05-01_9989.jpg',
    'Photo-2023-05-05-03-06-16_9990.jpg',
    'Photo-2023-05-05-03-12-38_9995.jpg',
    'Photo-2023-05-05-03-25-38_9996.jpg',
    'Photo-2023-05-05-03-38-20_9997.jpg',
    'Photo-2023-05-05-04-06-36_9998.jpg',
    'Photo-2023-05-05-04-06-42_9999.jpg',
    'Photo-2023-05-05-04-06-46_0001.jpg',
    'Photo-2023-05-05-04-13-02_0002.jpg',
    'Photo-2023-05-05-04-21-35_0003.jpg',
    'Photo-2023-05-05-04-36-01_0005.jpg',
    'Photo-2023-05-05-04-36-02_0006.jpg',
    'Photo-2023-05-05-04-40-54_0019.jpg',
    'Photo-2023-05-05-04-42-48_0058.jpg',
    'Photo-2023-05-05-17-56-14_0093.jpg',
    'Photo-2023-05-05-19-07-10_0095.jpg',
    'Photo-2023-05-05-19-07-23_0097.jpg',
    'Photo-2023-05-05-19-08-14_0102.jpg',
    'Photo-2023-05-05-19-08-17_0103.jpg',
    'Photo-2023-05-05-19-09-00_0108.jpg',
    'Photo-2023-05-05-19-09-18_0110.jpg',
    'Photo-2023-05-05-19-22-48_0137.jpg',
    'Photo-2023-05-05-19-41-06_0151.jpg',
    'Photo-2023-05-05-20-25-24_0160.jpg',
    'Photo-2023-05-05-20-25-26_0161.jpg',
    'Photo-2023-05-05-20-26-38_0172.jpg',
    'Photo-2023-05-05-20-37-30_0182.jpg',
    'Photo-2023-05-05-20-37-40_0183.jpg',
    'Photo-2023-05-05-20-46-27_0199.jpg',
    'Photo-2023-05-05-20-50-34_0219.jpg',
    'Photo-2023-05-05-21-16-06_0224.jpg',
    'Photo-2023-05-05-21-16-15_0227.jpg',
    'Photo-2023-05-05-22-22-59_0240.jpg',
    'Photo-2023-05-05-22-58-57_0241.jpg',
    'Photo-2023-05-05-22-59-01_0242.jpg',
    'Photo-2023-05-05-23-04-47_0246.jpg',
    'Photo-2023-05-05-23-07-41_0254.jpg',
    'Photo-2023-05-05-23-17-56_0258.jpg',
    'Photo-2023-05-05-23-20-00_0261.jpg',
    'Photo-2023-05-06-00-11-21_0267.jpg',
    'Photo-2023-05-06-00-14-11_0268.jpg',
    'Photo-2023-05-06-00-16-31_0272.jpg',
    'Photo-2023-05-06-00-16-50_0278.jpg',
    'Photo-2023-05-06-01-15-32_0292.jpg',
    'Photo-2023-05-06-01-55-52_0300.jpg',
    'Photo-2023-05-06-01-59-05_0303.jpg',
    'Photo-2023-05-06-03-12-32_0314.jpg',
    'Photo-2023-05-06-03-23-31_2171.jpg',
    'Photo-2023-05-06-04-55-55_0319.jpg',
    'Photo-2023-05-06-04-56-21_0321.jpg',
    'Photo-2023-05-06-15-50-49_0325.jpg',
    'Photo-2023-05-06-15-50-56_0326.jpg',
    'Photo-2023-05-06-15-51-14_0327.jpg',
    'Photo-2023-05-06-15-51-24_0330.jpg',
    'Photo-2023-05-06-15-51-44_0334.jpg',
    'Photo-2023-05-06-15-51-49_0335.jpg',
    'Photo-2023-05-06-21-41-35_2173.jpg',
    'Photo-2023-05-06-22-41-33_0365.jpg',
    'Photo-2023-05-06-23-11-40_0373.jpg',
    'Photo-2023-05-06-23-11-43_0374.jpg',
    'Photo-2023-05-06-23-12-24_0376.jpg',
    'Photo-2023-05-06-23-21-05_0379.jpg',
    'Photo-2023-05-07-15-53-33_0401.jpg',
    'Photo-2023-05-07-16-32-33_0406.jpg',
    'Photo-2023-05-07-17-02-05_0410.jpg',
    'Photo-2023-05-10-03-07-28_2405.jpg',
    'Photo-2023-05-10-03-07-30_2411.jpg',
    'Photo-2023-05-10-03-08-00_2415.jpg',
    'Photo-2023-05-10-03-08-02_2422.jpg',
    'Photo-2023-05-10-03-08-03_2426.jpg',
    'Photo-2023-05-10-03-09-03_2439.jpg',
    'Photo-2023-05-10-03-09-04_2440.jpg',
    'Photo-2023-05-10-03-09-40_2443.jpg',
    'Photo-2023-05-10-03-12-07_2449.jpg',
    'Photo-2023-05-10-03-12-09_2453.jpg',
    'Photo-2023-05-10-03-12-11_2469.jpg',
    'Photo-2023-05-10-03-12-14_2486.jpg',
    'Photo-2023-05-10-03-12-15_2492.jpg',
    'Photo-2023-05-10-03-12-17_2502.jpg',
    'Photo-2023-05-10-03-12-18_2507.jpg',
    'Photo-2023-05-10-03-12-19_2513.jpg',
    'Photo-2023-05-10-03-12-19_2514.jpg',
    'Photo-2023-05-10-03-13-07_2517.jpg',
    'Photo-2023-05-10-03-13-08_2520.jpg',
    'Photo-2023-05-10-03-13-08_2523.jpg',
    'Photo-2023-05-10-03-15-23_2526.jpg',
    'Photo-2023-05-10-03-15-24_2535.jpg',
    'Photo-2023-05-10-03-15-25_2538.jpg',
    'Photo-2023-05-10-03-15-26_2540.jpg',
    'Photo-2023-05-10-03-15-27_2543.jpg',
    'Photo-2023-05-10-03-15-27_2544.jpg',
    'Photo-2023-05-10-03-15-27_2545.jpg',
    'Photo-2023-05-10-03-15-27_2546.jpg',
    'Photo-2023-05-10-03-15-27_2547.jpg',
    'Photo-2023-05-10-03-15-27_2548.jpg',
    'Photo-2023-05-10-03-15-29_2553.jpg'
]).map((fileName, i) => ({
    id: i,
    url: `${ROOT_PATH}/${fileName}`,
    thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}) )

const eventVideos = Array.from([
    'Video-2023-04-30-18-14-24_1278.mp4',
    'Video-2023-05-04-19-54-55_9898.mp4'
]).map((fileName, i) => ({
    id: `v-${i}`,
    url: `${VIDEO_PATH}/${fileName}`,
    // thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}))

const familyEvent = {
    name: '德瑞蜜月',
    dateString: '2023/04/29 - 2023/05/08',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
