const ROOT_PATH = '/events/20230614-20230615菲利浦島'
const THUMBNAIL_PATH = '/thumbnails/20230614-20230615菲利浦島'
const VIDEO_PATH = '/thumbnails/20230614-20230615菲利浦島'

const eventPhotos = Array.from([
    'Photo-2023-06-15-15-44-32_2676.jpg',
    'Photo-2023-06-15-16-41-34_2678.jpg',
    'Photo-2023-06-15-21-19-39_2682.jpg',
    'Photo-2023-06-15-21-22-33_2686.jpg',
    'Photo-2023-06-15-21-38-54_2688.jpg',
    'Photo-2023-06-15-21-39-09_2689.jpg',
    'Photo-2023-06-15-21-39-36_2690.jpg',
    'Photo-2023-06-15-21-39-42_2691.jpg'
]).map((fileName, i) => ({
    id: i,
    url: `${ROOT_PATH}/${fileName}`,
    thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}) )

const eventVideos = Array.from([
    // 'Video-2023-06-15-15-44-14_2675.mp4',
    // 'Video-2023-06-15-16-40-23_2677.mp4'
]).map((fileName, i) => ({
    id: `v-${i}`,
    url: `${VIDEO_PATH}/${fileName}`,
    // thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}))

const familyEvent = {
    name: '菲利浦島',
    dateString: '2023/06/14 - 2023/06/15',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
