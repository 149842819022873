const ROOT_PATH = '/events/20221203登硬漢嶺'
const THUMBNAIL_PATH = '/thumbnails/20221203登硬漢嶺'

const eventPhotos = Array.from([
    'Photo-2022-12-04-21-18-41_0003.jpg',
    'Photo-2022-12-04-21-18-41_0004.jpg',
    'Photo-2022-12-04-21-18-42_0010.jpg',
    'Photo-2022-12-04-21-18-44_0014.jpg',
    'Photo-2022-12-04-21-18-45_0017.jpg',
    'Photo-2022-12-04-21-18-46_0018.jpg',
    'Photo-2022-12-04-21-18-46_0019.jpg',
    'Photo-2022-12-04-21-18-47_0020.jpg',
    'Photo-2022-12-04-21-18-47_0021.jpg',
    'Photo-2022-12-04-21-18-50_0028.jpg',
    'Photo-2022-12-04-21-18-51_0030.jpg'
]).map((fileName, i) => ({ id: i, url: `${ROOT_PATH}/${fileName}`, thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`, description: fileName }) )

const eventVideos = []

const familyEvent = {
    name: '登硬漢嶺',
    dateString: '2022/12/03',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
