import { useEffect } from 'react'
import { connect } from 'react-redux'
import { validateHash } from './ducks/actions'

const Gatekeeper = ({ validate }) => {
    useEffect(() => {
        document.title = "請登入"
    })

    return (
        <div className="h-[100vh] flex justify-center bg-secondary-500 items-center">
            <input className="text-center px-4 py-2 border-0 rounded"
                type="text"
                placeholder="請輸入暗號"
                onChange={ e => validate(e.target.value) } />
        </div>
    )
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({
    validate: (text) => dispatch(validateHash(text))
})

export default connect(null, mapDispatchToProps)(Gatekeeper)
