const ROOT_PATH = '/events/20221127歸寧'
const THUMBNAIL_PATH = '/thumbnails/20221127歸寧'

// files needing rotation
// const rotatePhotos = Array.from([
//     { name: 'Photo-2022-11-27-14-08-03_7676.jpg', rotateClassName: 'rotate-180' },
//     { name: 'Photo-2022-11-27-16-16-03_7727.jpg', rotateClassName: 'rotate-180' },
//     { name: 'Photo-2022-11-27-16-16-22_7728.jpg', rotateClassName: '-rotate-90' }
// ])

const eventPhotos = Array.from([
    'Photo-2022-11-27-13-58-42_7662.jpg',
    'Photo-2022-11-27-15-05-16_7717.jpg',
    'Photo-2022-11-27-14-08-03_7676.jpg',
    'Photo-2022-11-27-16-16-03_7727.jpg',
    'Photo-2022-11-27-15-05-16_7717.jpg',
    'Photo-2022-11-27-16-16-22_7728.jpg'
]).map((fileName, i) => ({ id: i, url: `${ROOT_PATH}/${fileName}`, thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`, description: fileName }) )

const eventVideos = []

const familyEvent = {
    name: '歸寧',
    dateString: '2022/11/27',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
