import PropTypes from 'prop-types'
import EventPhoto from './EventPhoto'
import EventVideo from './EventVideo'

const FamilyEvent = ({ viewIsOpened, eventPhotos, eventVideos, name, dateString, description }) => {
    return (
        <div>
            <h2 className="text-2xl">{ name }</h2>
            <p className="text-sky-500 mb-4">{ dateString }</p>
            <p className="mb-4">{ description }</p>
            <details open={ viewIsOpened }>
                <summary className="cursor-pointer select-none">展開所有照片</summary>
                <div className="flex justify-between flex-wrap">
                    {
                        eventPhotos.map(ep =>
                            <div key={ ep.id } className="w-full max-w-md mr-1 mb-4">
                                <EventPhoto { ...ep } />
                            </div>
                        )
                    }
                    {
                        eventVideos.map(ev =>
                            <div key={ ev.id } className="w-full max-w-md mr-1 mb-4">
                                <EventVideo { ...ev } />
                            </div>
                        )
                    }
                </div>
            </details>
        </div>
    )
}

FamilyEvent.propTypes = {
    viewIsOpened: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    dateString: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    eventPhotos: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            thumbnailUrl: PropTypes.string.isRequired,
            description: PropTypes.string
        })
    ).isRequired,
    eventVideos: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            // thumbnailUrl: PropTypes.string.isRequired,
            description: PropTypes.string
        })
    ).isRequired
}

export default FamilyEvent
