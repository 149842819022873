import e_20220825 from './events/20220825與姑姑聚餐'
import e_20221125 from './events/20221125婚禮'
import e_20221127 from './events/20221127歸寧'
import e_20221201 from './events/20221201團圓年夜飯'
import e_20221203 from './events/20221203登硬漢嶺'
import e_20221204 from './events/20221204與尚毅的家庭日'
import e_20221217 from './events/20221217與尚毅的家庭日'
import e_20221220 from './events/20221220阿美姨聚會'
import e_20221224 from './events/20221224台中媽慶生與平安夜'
import e_20230117 from './events/20230117虎頭山環保運動公園'
import e_20230121 from './events/20230121除夕年夜團圓'
import e_20230123 from './events/20230123回娘家'
import e_20230127 from './events/20230127 Two Families'
import e_20230128 from './events/20230128大伯二姑聚會'
import e_20230129 from './events/20230129大姨聚會'
import e_20230204 from './events/20230204桃園-墨爾本'
import e_20230413 from './events/20230413 Yara巧克力工廠'
import e_20230424 from './events/20230424動物園'
import e_20230429 from './events/20230429-20230508德瑞蜜月'
import e_20230520 from './events/20230520佛堂安座'
import e_20230614 from './events/20230614-20230615菲利浦島'
import e_2023_culinary from './events/2023墨爾本烹飪集合'

const familyEvents = [
    e_2023_culinary,
    e_20230614,
    e_20230520,
    e_20230429,
    e_20230424,
    e_20230413,
    e_20230204,
    e_20230129,
    e_20230128,
    e_20230127,
    e_20230123,
    e_20230121,
    e_20230117,
    e_20221224,
    e_20221220,
    e_20221217,
    e_20221204,
    e_20221203,
    e_20221201,
    e_20221127,
    e_20221125,
    e_20220825,
]

export default familyEvents
