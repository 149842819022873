import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { HOME_PAGE, GATEKEEPER_PAGE } from './ducks/actions'
import { selectCurrentPage } from './ducks/selectors'
import HomePage from '../home/HomePage'
import Gatekeeper from '../gatekeeper/Gatekeeper'

const Router = ({ accessAllowed, currentPage }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })

    if (accessAllowed) {
        switch(currentPage) {
            case HOME_PAGE:
                return <HomePage />
            case GATEKEEPER_PAGE:
                return <HomePage />
            default:
                return <HomePage />
        }
    } else {
        return <Gatekeeper />
    }
}

Router.propTypes = {
    currentPage: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
    currentPage: selectCurrentPage(state),
    accessAllowed: state.gatekeeper.accessAllowed
})

export default connect(mapStateToProps)(Router)
