const ROOT_PATH = '/events/20230127 Two Families'
const THUMBNAIL_PATH = '/thumbnails/20230127 Two Families'

const eventPhotos = Array.from([
    'Photo-2023-01-25-13-27-44_0517.jpg',
    'Photo-2023-01-25-13-27-54_0518.jpg',
    'Photo-2023-01-25-13-27-58_0519.jpg',
    'Photo-2023-01-25-14-13-40_0522.jpg',
    'Photo-2023-01-25-20-36-45_0533.jpg',
    'Photo-2023-01-25-20-36-45_0534.jpg',
    'Photo-2023-01-25-20-36-46_0535.jpg',
    'Photo-2023-01-25-20-36-46_0536.jpg',
    'Photo-2023-01-25-20-36-48_0537.jpg',
    'Photo-2023-01-27-20-06-35_1904.jpg',
    'Photo-2023-01-27-20-06-45_1905.jpg',
    'Photo-2023-01-27-20-07-31_1909.jpg',
    'Photo-2023-01-27-20-07-34_1911.jpg',
    'Photo-2023-01-27-20-08-11_1913.jpg',
    'Photo-2023-01-27-20-08-19_1915.jpg',
    'Photo-2023-01-27-20-08-24_1924.jpg',
    'Photo-2023-01-27-20-09-17_1934.jpg',
    'Photo-2023-01-27-20-10-07_1947.jpg',
    'Photo-2023-01-27-20-10-30_1949.jpg',
    'Photo-2023-01-27-20-10-35_1950.jpg',
    'Photo-2023-01-27-20-10-57_1951.jpg',
    'Photo-2023-01-27-20-11-18_1954.jpg',
    'Photo-2023-01-27-20-12-08_1957.jpg',
    'Photo-2023-01-27-20-12-23_1960.jpg',
    'Photo-2023-01-27-20-12-57_1964.jpg',
    'Photo-2023-01-27-20-13-33_1974.jpg',
    'Photo-2023-01-27-20-14-15_1977.jpg'
]).map((fileName, i) => ({
    id: i,
    url: `${ROOT_PATH}/${fileName}`,
    thumbnailUrl: `${THUMBNAIL_PATH}/${fileName}`,
    description: fileName
}) )

const eventVideos = []

const familyEvent = {
    name: 'Two Families',
    dateString: '2023/01/27',
    description: '',
    eventPhotos,
    eventVideos
}

export default familyEvent
